import { useQuery } from 'react-query'
import { getCacheBody } from '../utils/shared'
import { domoscioAccess } from '../api/domoscio_access'
import { useContext } from 'react'
import { Session } from '../contexts/parameters'
import { DataIsReadyContext } from '../contexts/dataIsReadyContext'
import { FilterBodyContext } from '../contexts/parameters/filterBodyContext'
import { useRiseUpClient } from './useRiseUpClient'
import I18n from '../locales/I18n'
import { ObjectivesFilterContext } from '../contexts/parameters/filterContext'
// import mockedData from './mockData.json'
type Tab =
  | 'HubProgression'
  | 'LockProgression'
  | 'LockEngagement'
  | 'HubEngagement'
  | 'HubUsage'
  | 'LockUsage'
interface Args {
  tab: Tab
  onSuccess?: () => void
}

const extarctDataForRuFlow = (data: any, screen: Tab) => {
  if (screen === 'LockEngagement' || screen === 'LockProgression') {
    return data?.body?.objectives[0]
  } else {
    return data?.body?.learning_programs[0]
  }
}

export const useFetchAnalyticsDatas = ({ tab }: Args) => {
  const session = useContext(Session.Dispatch)
  const sessionData = useContext(Session.State)
  const { setIsDataReady } = useContext(DataIsReadyContext)
  const bodyData = useContext(FilterBodyContext)
  const { selectedObjective } = useContext(ObjectivesFilterContext)
  const { isRiseup } = useRiseUpClient()

  // wait for filters fetches
  const isEnabled =
    bodyData.learning_program_uid.length > 0 &&
    bodyData.student_group_uid.length > 0 &&
    bodyData.start_date !== '' &&
    selectedObjective.length > 0

  let body = { ...bodyData } as any
  const route = 'get' + tab

  if (isRiseup) {
    body = {
      start_date: new Date('2010-01-01').toISOString(),
      end_date: new Date().toISOString()
    }
  }

  if (tab === 'LockProgression' && isRiseup) {
    body.lang = I18n.locale
  }
  body.scope = bodyData.objective_uid.length > 0 ? 'objectives' : 'learning_programs'

  if (tab.includes('Usage')) {
    body.scope = 'learning_programs'
  }
  if (isRiseup) {
    if (tab === 'LockProgression' || tab === 'LockEngagement') {
      body.scope = 'objectives'
    }
  }

  if (body.scope === 'learning_programs') {
    delete body.objective_uid
  }

  const { data, status, isLoading, isSuccess } = useQuery(
    [tab, getCacheBody(body)],
    async () => {
      const dataProgressionHub = await domoscioAccess[route](body)
      return isRiseup ? extarctDataForRuFlow(dataProgressionHub, tab) : dataProgressionHub
    },
    {
      enabled: isRiseup ? true : isEnabled,
      onSuccess: data => {
        if (data.statusCode === 200) {
          const experience = data?.body?.learning_programs[0]
          const objective = data?.body?.objectives[0]

          if (experience) {
            const isExportDisabled = experience?.exportDisable
            session({
              isExportDisabled: {
                ...(sessionData.isExportDisabled || {}),
                [experience?.uid]: isExportDisabled
              }
            })
          }

          if (objective) {
            const isExportDisabled = objective?.exportDisable
            session({
              isExportDisabled: {
                ...(sessionData.isExportDisabled || {}),
                [body?.learning_program_uid[0]]: isExportDisabled
              }
            })
          }
        }
        setIsDataReady(true)
      }
    }
  )

  return { data, status, isLoading, isSuccess }
}
