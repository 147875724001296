import I18n from 'i18n-js'
import fr from './fr.json'
import en from './en.json'
import ch from './zh-CN.json'
import es from './es-ES.json'
import sa from './ar.json'
import pt from './pt-PT.json'
import ru from './ru.json'
import jp from './ja.json'
import de from './de.json'
import it from './it.json'
import nl from './nl.json'
import th from './th.json'
import pl from './pl.json'
import bg from './bg.json'
import cz from './cs.json'
import gr from './el.json'
import kr from './ko.json'
import tr from './tr.json'
import vn from './vi.json'
import rs from './sr-CS.json'
import br from './pt-BR.json'
import ua from './uk.json'
import no from './no.json'
import ro from './ro.json'

import { domLocales } from '@domoscio/domoscio-sdk-js'

I18n.fallbacks = true
I18n.translations = {
  fr,
  en,
  ch,
  es,
  sa,
  pt,
  ru,
  jp,
  de,
  it,
  nl,
  th,
  pl,
  bg,
  cz,
  gr,
  kr,
  tr,
  vn,
  rs,
  br,
  ua,
  no,
  ro
} as { [key: string]: any }

// Merge src & domLocales translations
I18n.translations = I18n.extend(I18n.translations || {}, domLocales) as { [key: string]: any }

I18n.domoscio = [
  'fr',
  'en',
  'ch',
  'es',
  'sa',
  'pt',
  'ru',
  'jp',
  'de',
  'it',
  'nl',
  'th',
  'pl',
  'bg',
  'cz',
  'gr',
  'kr',
  'tr',
  'vn',
  'rs',
  'br',
  'ua',
  'no',
  'ro'
] as string[]

export default I18n
