import { useContext } from 'react'
import { Client } from '../contexts/parameters'

export const useRiseUpClient = () => {
  const client = useContext(Client.State)

  if (!client) {
    throw new Error('useRiseUpClient must be used within an Parameters provider')
  }

  return {
    isRiseup: client.riseup_client,
    primaryColor: client.mqb_primary_color
  }
}
